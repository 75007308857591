<template>
  <div>
    <b-card no-body>
      <b-card title="Glass Usage Price" header-tag="header">
        <template v-slot:header>
          <product-form-nav link="glass" :productId="id"></product-form-nav>
        </template>
        <b-card-text>
          <b-row>
            <b-col lg="4" md="3">
              <b-form-group>
                <label>Distance</label>
                <b-form-input v-model="text" placeholder=""></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="4" md="3">
              <b-form-group>
                <label>Reading</label>
                <b-form-input v-model="text" placeholder=""></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="4" md="3">
              <b-form-group>
                <label>Progressive</label>
                <b-form-input v-model="text" placeholder=""></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="4" md="3">
              <b-form-group>
                <label>Bifocal</label>
                <b-form-input v-model="text" placeholder=""></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="4" md="3">
              <b-form-group>
                <label>Non-Priscription</label>
                <b-form-input v-model="text" placeholder=""></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-right">
              <b-button variant="primary"
                ><i class="las la-save"></i> Save and Continue</b-button
              >
            </b-col>
          </b-row>
        </b-card-text>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import ProductFormNav from "./components/ProductFormNav.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  components: {
    ProductFormNav,
  },
  data() {
    return {
      id: this.$route.params.id?parseInt(this.$route.params.id):null,
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Dashboard",
        route: "/dashboard",
      },
      {
        title: "Products",
        route: "/products",
      },
      {
        title: "Manage Product : Glass Usage",
      },
    ]);
  },
};
</script>

<style></style>
